import React, { useState } from 'react';
import './infopages.scss';
import { Helmet } from "react-helmet-async";

const Termscondition = () => {

  return (
    <>
        <Helmet>
      <title>Technico Group Terms & Conditions - User Agreements</title>
      <meta name="description" content="Review Technico Agri Sciences Ltd. website terms and conditions outlining user responsibilities, access guidelines, and legal agreements for using our services." />
    </Helmet>
      <div className='infopages Termscondition'>
      <div class="container">
					
          <h1>Terms and Conditions of Website Use</h1>
          <p>This page states the Terms and Conditions under which you (Visitor) may visit this Web site. Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this site. TECHNICO AGRI SCIENCES LIMITED reserve their respective rights to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the Terms and Conditions,because they are binding on all users of this Web Site.</p>
          <h3>Website Usage</h3>
          <p>Any personal information shared by you in this Website shall be kept confidential. Personal information, shared by you, shall be used for doing the intended business with you.<br/> We assures you that in the event of your personal information being shared by the Company&nbsp; such sharing of information shall be for the purpose of doing the intended business with you. You hereby grant your consent to TECHNICO AGRI SCIENCES LIMITED to share such information with its subsidiaries, business associates etc.<br/> TECHNICO AGRI SCIENCES LIMITEDshall not be responsible in any manner whatsoever for any violation or misuse of your personal information by unauthorized person consequent to misuse of the internet environment.</p>
          <h3>Use of Content</h3>
          <p>All logos, brands and marks appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by TECHNICO AGRI SCIENCES Limited and / or its associate entities who feature on this website. The use of these properties or any other content on this site, except as provided in these terms and conditions or in the site content, is strictly prohibited.<br/> You may not sell or modify the content of this Web Site or reproduce,display,publicly perform,distribute,or otherwise use the materials in any way for any public or commercial purpose without the respective organisation’s or entity’s written permission.</p>
          <h3>Acceptable Site Use</h3>
          <h4>Security Rules</h4>
          <p>Visitors are prohibited from violating or attempting to violate the security of the Web site, including, without limitation,</p>
          <p>accessing data not intended for such user or logging into a server or account which the user is not authorised to access,</p>
          <p>attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorisation,</p>
          <p>attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting a virus or “trojan horse” to the Web site, overloading, “flooding”, “mail bombing” or “crashing”, or</p>
          <p>sending unsolicited electronic mail, including promotions and/or advertising of products or services. Violations of system or network security may result in civil or criminal liability. TECHNICO AGRI SCIENCES LIMITED and / or its associate entities will have the right to investigate occurrences that they suspect as involving such violations and will have the right to involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</p>
          <h4>General Rules</h4>
          <p>Visitors may not use the Web Site in order to transmit, distribute, store or destroy material</p>
          <p>that could constitute or encourage conduct that would be considered a criminal offense or violate any applicable law or regulation,</p>
          <p>in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy or publicity of other personal rights of others, or</p>
          <p>that is libelous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful.</p>
          <h3>Links to/from other Web Sites</h3>
          <p>This Web site contains links to other Web Sites. These links are provided solely as a convenience to you. Wherever such link/s lead to sites which do not belong to TECHNICO AGRI SCIENCES LIMITED and / or its associate entities, TECHNICO AGRI SCIENCES LIMITED is not responsible for the content of linked sites and does not make any representations regarding the correctness or accuracy of the content on such Web Sites. If you decide to access such linked Web Sites, you do so at your own risk.</p>
          <p>Similarly, this Web site can be made accessible through a link created by other Web sites. Access to this Web site through such link/s shall not mean or be deemed to mean that the objectives, aims, purposes, ideas, concepts of such other Web sites or their aim or purpose in establishing such link/s to this Web site are necessarily the same or similar to the idea, concept, aim or purpose of our web site or that such links have been authorised by TECHNICO AGRI SCIENCES LIMITED and / or its associate entities. We are not responsible for any representation/s of such other Web sites while affording such link and no liability can arise upon TECHNICO AGRI SCIENCES LIMITED and / or its associate entities consequent to such representation, its correctness or accuracy. In the event that any link/s afforded by any other Web site/s derogatory in nature to the objectives, aims, purposes, ideas and concepts of this Web site is utilised to visit this Web site and such event is brought to the notice or is within the knowledge of TECHNICO AGRI SCIENCES LIMITED and / or its associate entities, civil or criminal remedies as may be appropriate shall be invoked.</p>
          <h4>Indemnity</h4>
          <p>You agree to defend, indemnify, and hold harmless TECHNICO AGRI SCIENCES LIMITED and/ or its associate entities, their officers, directors, employees and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees,alleging or resulting from your use of the Web site material or your breach of these terms and conditions of Web Site use.</p>
          <h4>Liability</h4>
          <p>While all reasonable care has been taken in providing the content on this Web Site, TECHNICO AGRI SCIENCES LIMITED. and / or its associate entities shall not be responsible or liable as to the completeness or correctness of such information and any or all consequential liabilities arising out of use of any information or contents on this Web Site.</p>
          <p>No warranty is given that the Web Site will operate error-free or that this Web Site and its server are free of computer viruses or other harmful mechanisms. If your use of the Web site results in the need for servicing or replacing equipment or data, TECHNICO AGRI SCIENCES LIMITED and / or its associate entities are not responsible for those costs.</p>
          <p>The web site is provided on an ‘as is’ basis without any warranties either express or implied whatsoever. TECHNICO AGRI SCIENCES LIMITED. and / or its associate entities, to the fullest extent permitted by law, disclaims all warranties, including non-infringement of third parties rights, and the warranty of fitness for a particular purpose and makes no warranties about the accuracy, reliability, completeness, or timeliness of the content, services, software, text, graphics, and links.</p>
          
          <p>For further information you may reach us at <a href="mailto:technico@technituberindia.com">technico@technituberindia.com</a></p>
          
      </div>
      </div>
    </>
  )
};

export default Termscondition;
